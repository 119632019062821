import { Messages } from '@/root/shared-types'

export const bluewaveMessages: Messages = {
  headerLangSwitcher: {
    id: 'header.lang-switcher',
    defaultMessage: 'English',
    description: 'Locale switcher label in header',
  },
  ticketBannerTitle: {
    id: 'ticket.banner.title',
    defaultMessage: 'THANK YOU!',
    description: "Ticket's page banner title",
  },
  ticketBannerBody: {
    id: 'ticket.banner.body',
    defaultMessage: "Your payment succeeded, below you'll find your ticket(s)",
    description: "Ticket's page banner body",
  },
  ticketBannerErrorTitle: {
    id: 'ticket.error-banner.title',
    defaultMessage: 'WARNING',
    description: "Ticket's page error-banner title",
  },
  ticketBannerErrorBody: {
    id: 'ticket.error-banner.body',
    defaultMessage:
      'The server throws random errors with no specific codes. let’s pretend it’s aliens invasion, please reboot the Earth!',
    description: "Ticket's page error-banner body",
  },
  ticketBookingAwaitingPaymentTitle: {
    id: 'ticket.booking-awaiting-payment.title',
    defaultMessage: 'Your booking is awaiting payment within 48 hours',
    description: 'Awaiting payment banner title',
  },
  ticketBookingAwaitingPaymentBody: {
    id: 'ticket.booking-awaiting-payment.body',
    defaultMessage: 'If no payment received before, booking will be automatically cancelled.',
    description: 'Awaiting payment banner body',
  },
  ticketTicketNumber: {
    id: 'ticket.ticket-number',
    defaultMessage: 'Ticket nr.',
    description: 'Ticket number',
  },
  ticketBookingNumber: {
    id: 'ticket.booking-number',
    defaultMessage: 'Booking nr.',
    description: 'Booking number',
  },
  ticketVehicleRegNumber: {
    id: 'ticket.vehicle_nr',
    defaultMessage: 'Vehicle reg. nr',
    description: 'Vehicle reg. nr',
  },
  ticketActionButtonsDownload: {
    id: 'ticket.action-buttons.download',
    defaultMessage: 'Download invoice',
    description: 'Download the ticket',
  },

  ticketActionButtonsPrint: {
    id: 'ticket.action-buttons.print',
    defaultMessage: 'Print out invoice',
    description: 'Print the ticket',
  },
  ticketPaymentInfoTitle: {
    id: 'ticket.payment-info.title',
    defaultMessage: 'PLEASE USE FOLLOWING PAYMENT DETAILS FOR YOUR TRANSFER:',
    description: 'Ticket payment info title',
  },
  ticketPaymentRecipient: {
    id: 'ticket.payment-recipient',
    defaultMessage: 'Payment recipient:',
    description: 'Payment recipient',
  },
  ticketPaymentRecipientBankAccountNr: {
    id: 'ticket.payment-recipient-bank-account-nr',
    defaultMessage: 'Payment recipient:',
    description: 'Payment recipient',
  },
  ticketPaymentRecipientBank: {
    id: 'ticket.payment-recipient-bank',
    defaultMessage: 'Recipient bank:',
    description: 'Recipient bank',
  },
  ticketPaymentRecipientBankCode: {
    id: 'ticket.payment-recipient-bank-code',
    defaultMessage: 'Recipient bank code:',
    description: 'Recipient bank code',
  },
  ticketPaymentDescription: {
    id: 'ticket.payment-payment-description',
    defaultMessage: 'Payment description:',
    description: 'Payment description',
  },
  ticketPaymentAmount: {
    id: 'ticket.payment-payment amount',
    defaultMessage: 'Payment amount:',
    description: 'Payment amount',
  },
}
