import { FC, PropsWithChildren, useEffect } from 'react'
import { currentTranslations } from '@/lang'
import { IntlProvider } from 'react-intl'
import { useRouter } from 'next/router'
import { rtlLocales } from '@/root/shared-consts'

const I18nProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useRouter()
  const currentLocaleTranslations = currentTranslations[locale as keyof typeof currentTranslations]

  useEffect(() => {
    if (rtlLocales.includes(locale as string)) {
      document.documentElement.setAttribute('dir', 'rtl')
      return
    }
    document.documentElement.setAttribute('dir', 'ltr')
  }, [locale])

  return (
    <IntlProvider locale={locale as string} messages={currentLocaleTranslations}>
      {children}
    </IntlProvider>
  )
}

export default I18nProvider
