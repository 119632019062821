import { IStaticContent } from '@/root/shared-types'
import { FC, PropsWithChildren } from 'react'
import { StaticContentContext } from './StaticContentContext'

interface IStaticContentProviderProps extends PropsWithChildren {
  value: IStaticContent
}

const StaticContentProvider: FC<IStaticContentProviderProps> = ({ value, children }) => {
  return <StaticContentContext.Provider value={value}>{children}</StaticContentContext.Provider>
}

export default StaticContentProvider
