import { Messages } from '@/root/shared-types'

export const admaritimeMessages: Messages = {
  headerLangSwitcher: {
    id: 'header.lang-switcher',
    defaultMessage: 'عربي',
    description: 'Locale switcher label in header',
  },
  headerRegisterOrLoginButton: {
    id: 'header.register-or-login-button',
    defaultMessage: 'Register/Login',
    description: 'Text for register/login button in header',
  },
  ticketBannerTitle: {
    id: 'ticket.banner.title',
    defaultMessage: 'THANK YOU!',
    description: "Ticket's page banner title",
  },
  ticketBannerBody: {
    id: 'ticket.banner.body',
    defaultMessage:
      "Your payment has been successful and confirmation has been sent to your email. Click on 'Download' to access your ticket and booking.",
    description: "Ticket's page banner body",
  },
  ticketBannerErrorTitle: {
    id: 'ticket.error-banner.title',
    defaultMessage: 'WARNING',
    description: "Ticket's page error-banner title",
  },
  ticketBannerErrorBody: {
    id: 'ticket.error-banner.body',
    defaultMessage:
      'The server throws random errors with no specific codes. let’s pretend it’s aliens invasion, please reboot the Earth!',
    description: "Ticket's page error-banner body",
  },
  ticketActionButtonsPrint: {
    id: 'ticket.action-buttons.print',
    defaultMessage: 'Print out',
    description: 'Label for print action button',
  },
  ticketActionButtonsDownload: {
    id: 'ticket.action-buttons.download',
    defaultMessage: 'Download',
    description: 'Label for download action button',
  },
  ticketActionButtonsCancel: {
    id: 'ticket.action-buttons.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for cancel action button',
  },
  ticketPrintedTicketNumber: {
    id: 'ticket.printed-ticket.number',
    defaultMessage: 'Ticket No:',
    description: 'Ticket number label',
  },
  ticketPrintedTicketVehicleNumberLabel: {
    id: 'ticket.printed-ticket.vehicle-number-label',
    defaultMessage: 'Vehicle reg. No',
    description: 'Vehicle number label',
  },
  cancelAndRemoveTicket: {
    id: 'reservation-refund.cancel-remove-title',
    defaultMessage: 'Cancel and remove E-ticket nr.',
    description: 'label for cancelAndRemoveTicket',
  },
  refundWarningText: {
    id: 'refund.warning-text',
    defaultMessage: 'You are about to cancel and remove following E-ticket:',
    description: 'Refund warning text',
  },
  keepTicket: {
    id: 'reservation-refund.keep-ticket',
    defaultMessage: 'I want to keep this ticket',
    description: 'label for keepTicket',
  },
  amountRefundTipMaritime: {
    id: 'reservation-refund.amount-refund-tip-maritime',
    defaultMessage:
      '* - will be refunded to you by the means on MPAY service, using same payment gateway that you chosen while purchasing the ticket(s).',
    description: 'label for amountRefundTip(maritime)',
  },
  amountPaid: {
    id: 'reservation-refund.amount-paid',
    defaultMessage: "Amount you've paid:",
    description: 'label for amountPaid',
  },
  percentRefund: {
    id: 'reservation-refund.percent-refund',
    defaultMessage: 'Percent of refund:',
    description: 'label for percentRefund',
  },
  amountRefund: {
    id: 'reservation-refund.amount-refund',
    defaultMessage: 'Amount to be refund:',
    description: 'label for amountRefund',
  },
  removeTicket: {
    id: 'reservation-refund.refund-remove-ticket',
    defaultMessage: 'Cancel and remove this E-ticket',
    description: 'label for removeTicket',
  },
  refundAgreement: {
    id: 'reservation-refund.refund-agreement',
    defaultMessage:
      "Yes, I want to cancel and remove this E-ticket and I'm agree to receive above mentioned amount as per Service Provider Agreement.",
    description: 'label for refundAgreement',
  },
  cancelRouteMaritime: {
    id: 'reservation-refund.cancel-route-maritime',
    defaultMessage: 'Cancel this route',
    description: 'label for cancelRoute',
  },
  cancelled: {
    id: 'reservation-refund.sail-cancelled',
    defaultMessage: 'cancelled',
    description: 'label for cancelled',
  },
  ticketWasRefundTipMaritime: {
    id: 'reservation-refund.was-refund-tip-maritime',
    defaultMessage:
      'Amount of <b>{refundAmount}</b> will be returned to you by the means on MPAY service, using same payment gateway that you chosen while purchasing the ticket(s). If the refund was not received within 5-7 business days to your bank account please contact our Customer Support',
    description: 'label for ticketWasRefundTip',
  },
  mayClose: {
    id: 'reservation-refund.may-close',
    defaultMessage: 'You may close this window now.',
    description: 'label for mayClose',
  },
  ticketWasRefund: {
    id: 'reservation-refund.was-refund',
    defaultMessage: 'Your ticket nr. <b>{reservationId}</b> is successfully cancelled / deleted',
    description: 'label for ticketWasRefund',
  },
}
