import '@/pages/globals.css'
import { Providers } from '@/root/shared-providers'
import App from 'next/app'
import type { AppContext, AppProps } from 'next/app'
import { THEME } from '@/root/shared-types'
import Head from 'next/head'

export default function Sales2App({ Component, pageProps, theme }: AppProps & { theme: THEME }) {
  const favIconPath = {
    bluewave: '/bluewave/icons/faviconBluewave.ico',
    admaritime: '/admaritime/icons/faviconAdmaritime.ico',
  }
  return (
    <>
      <Head>
        <link rel="shortcut icon" href={favIconPath[theme]} />
      </Head>
      <Providers theme={theme}>
        <Component {...pageProps} />
      </Providers>
    </>
  )
}

Sales2App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const theme = process.env.THEME || process.env.NEXT_PUBLIC_THEME

  return {
    ...appProps,
    theme,
  }
}
