import { admaritimeTranslations } from './admaritime'
import { admaritimeMessages } from './admaritime/messages'
import { Messages } from '@/root/shared-types'
import { bluewaveMessages } from '@/lang/bluewave/messages'
import { bluewaveTranslations } from '@/lang/bluewave'

const translations = {
  admaritime: admaritimeTranslations,
  bluewave: bluewaveTranslations,
}

const messages = {
  admaritime: admaritimeMessages,
  bluewave: bluewaveMessages,
}

export const currentTranslations = translations[process.env.NEXT_PUBLIC_THEME as keyof typeof translations] || {}
export const currentMessages: Messages = messages[process.env.NEXT_PUBLIC_THEME as keyof typeof translations] || {}
