import { FC, PropsWithChildren } from 'react'
import ConfigProvider from '../ConfigProvider/ConfigProvider'
import I18nProvider from '../I18nProvider/I18nProvider'
import { THEME } from '@/root/shared-types/index'

interface Props extends PropsWithChildren {
  theme: THEME
}

const Providers: FC<Props> = ({ children, theme }) => {
  return (
    <ConfigProvider theme={theme}>
      <I18nProvider>{children}</I18nProvider>
    </ConfigProvider>
  )
}

export default Providers
