import { FC, PropsWithChildren } from 'react'
import { ConfigContext } from './ConfigContext'
import { THEME } from '@/root/shared-types/index'
import { getCurrentConfig } from '@/configs/theme/index'

interface Props extends PropsWithChildren {
  theme: THEME
}

const ConfigProvider: FC<Props> = ({ children, theme }) => {
  return <ConfigContext.Provider value={getCurrentConfig(theme)}>{children}</ConfigContext.Provider>
}

export default ConfigProvider
