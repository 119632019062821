import themeAdmaritime from './theme-admaritime.json'
import themeBluewave from './theme-bluewave.json'
import { THEME } from '@/root/shared-types'

const configs = {
  [themeAdmaritime.theme]: themeAdmaritime,
  [themeBluewave.theme]: themeBluewave,
}
export const currentConfig = configs[process.env.NEXT_PUBLIC_THEME as string]
export const getCurrentConfig = (theme: THEME) => configs[theme]
